var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: {
          title: _vm.title,
          breadcrumbs: _vm.breadcrumbs,
          actionButton: _vm.actionButton,
          showButton: _vm.isAdmin
        },
        on: { clicked: _vm.inviteUser }
      }),
      _c(
        "v-card",
        { staticClass: "rounded-card", attrs: { flat: "", outlined: "" } },
        [
          _c("v-data-table", {
            style: { cursor: _vm.isAdmin ? "" : "pointer" },
            attrs: {
              headers: _vm.headers,
              items: _vm.users.items,
              options: _vm.options,
              "server-items-length": _vm.users.count,
              loading: _vm.loading,
              "no-data-text": _vm.$t("messages.emptyState", {
                entity: _vm.$tc("models.user.entity")
              }),
              "no-results-text": _vm.$t("messages.emptyState", {
                entity: _vm.$tc("models.user.entity")
              }),
              flat: ""
            },
            on: {
              "update:options": [
                function($event) {
                  _vm.options = $event
                },
                _vm.updateOptions
              ],
              "click:row": _vm.handleRowClick
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c("app-form", {
                        ref: "form",
                        staticClass: "py-3 px-5",
                        attrs: {
                          fields: _vm.searchFields,
                          submitButton: _vm.submitButton,
                          cancelButton: _vm.cancelButton,
                          footerClass: "text-right col-md-2 col-sm-4 col-xs-12"
                        },
                        on: {
                          canceled: _vm.searchUsers,
                          submitted: _vm.searchUsers
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.firstName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-avatar",
                            { attrs: { size: "45" } },
                            [
                              _c("app-avatar", {
                                staticClass: "no-padding",
                                attrs: {
                                  url: _vm.getFileUrl(item.avatar),
                                  alternative: item.initials
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-content", {
                            domProps: { textContent: _vm._s(item.name) }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.department.id",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.department.name) + " ")]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm.isAdmin
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                small: "",
                                to: {
                                  name: "UserPublicProfile",
                                  params: { uuid: item.id }
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-eye")])],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin || item.id === _vm.currentUser.id
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                small: "",
                                to: {
                                  name: "EditProfile",
                                  params: { uuid: item.id }
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-pen")])],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin && item.id !== _vm.currentUser.id
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "", small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteUser(item)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-trash-can-outline")])],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }