<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" :actionButton="actionButton"
      @clicked="inviteUser" :showButton="isAdmin"/>
    <v-card class="rounded-card" flat outlined>
      <v-data-table :headers="headers" :items="users.items" :options.sync="options" @update:options="updateOptions"
      :server-items-length="users.count" :loading="loading"
      :no-data-text="$t('messages.emptyState', { entity: $tc('models.user.entity') })"
      :no-results-text="$t('messages.emptyState', { entity: $tc('models.user.entity') })"
      @click:row="handleRowClick" flat :style="{cursor: isAdmin ? '' : 'pointer'}">
        <template v-slot:top>
          <app-form ref="form" :fields="searchFields"
              @canceled="searchUsers"
              @submitted="searchUsers"
              :submitButton="submitButton"
              :cancelButton="cancelButton"
              class="py-3 px-5"
              footerClass="text-right col-md-2 col-sm-4 col-xs-12"
          />
        </template>
        <template v-slot:[`item.firstName`]="{item}">
          <v-list-item>
            <v-list-item-avatar size="45">
              <app-avatar :url="getFileUrl(item.avatar)" :alternative="item.initials" class="no-padding"/>
            </v-list-item-avatar>
            <v-list-item-content v-text="item.name" />
          </v-list-item>
        </template>
        <template v-slot:[`item.department.id`]="{item}">
          {{ item.department.name }}
        </template>
        <template v-slot:[`item.actions`]="{item}">
            <v-btn v-if="isAdmin" icon small :to="{name: 'UserPublicProfile', params: {uuid: item.id}}">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn v-if="isAdmin || item.id === currentUser.id" icon small
              :to="{name: 'EditProfile', params: {uuid: item.id}}">
              <v-icon>mdi-pen</v-icon>
            </v-btn>
            <v-btn v-if="isAdmin && item.id !== currentUser.id" icon small @click="deleteUser(item)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      title: {
        text: this.$tc('models.user.entity', 2),
        icon: 'mdi-account-group'
      },
      breadcrumbs: [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$tc('models.user.entity', 2),
        to: {name: 'Users'},
        exact: true
      }],
      actionButton: {
        text: this.$t('actions.sendInvitation'),
        icon: 'mdi-card-account-mail'
      },
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['employeeId'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false,
        filterBy: {}
      },
      headers: [
        {
          text: this.$t('models.user.attributes.employeeId'),
          value: 'employeeId'
        },
        {
          text: this.$t('models.user.attributes.name'),
          value: 'firstName'
        },
        {
          text: this.$t('models.user.attributes.department'),
          value: 'department.id'
        },
        {
          text: this.$t('models.user.attributes.jobTitle'),
          value: 'jobTitle'
        },
        {
          text: this.$t('models.user.attributes.email'),
          value: 'email'
        },
        {
          text: this.$t('models.user.attributes.phoneNumber'),
          value: 'phoneNumber'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px'
        }],
      submitButton: {
        iconButton: true,
        icon: 'mdi-magnify',
        classes: 'primary--text'
      },
      cancelButton: {
        iconButton: true,
        icon: 'mdi-trash-can-outline',
        classes: 'secondary--text'
      }
    };
  },
  computed: {
    ...mapGetters(['users', 'isAdmin', 'currentUser']),
    searchFields() {
      return {
        column: {
          type: 'select',
          key: 'column',
          options: this._.dropRight(this.headers),
          value: '',
          rules: [{name: 'required'}],
          itemText: 'text',
          itemValue: 'value',
          label: this.$t('views.users.searchKey'),
          classes: 'col-md-5 col-sm-4 col-xs-12 pt-3 pb-0',
          outlined: false
        },
        searchValue: {
          type: 'text',
          key: 'searchValue',
          value: '',
          rules: [{name: 'required'}],
          label: this.$t('views.users.searchValue'),
          classes: 'col-md-5 col-sm-4 col-xs-12 pt-3 pb-0',
          outlined: false,
          clearable: true
        }
      };
    }
  },
  methods: {
    ...mapActions(['getUsers', 'destroyUser']),
    updateOptions(options) {
      this.loading = true;
      this.getUsers(options).then(() => this.loading = false);
    },
    inviteUser() {
      this.$router.push({name: 'UserInvitation'});
    },
    handleRowClick(user) {
      (this.isAdmin || user.id === this.currentUser.id) ? '' : this.goToProfile(user);
    },
    goToProfile(user) {
      this.$router.push({
        name: 'UserPublicProfile',
        params: {
          uuid: user.id
        }
      });
    },
    deleteUser(user) {
      this.$confirm(this.$t('messages.confirmation.delete', {entity: user.name}), {
        buttonTrueText: this.$t('actions.confirm'),
        buttonFalseText: this.$t('actions.cancel')
      }).then((confirmed) => {
        if (confirmed) {
          this.destroyUser(user.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    },
    searchUsers() {
      this.options.filterBy = {};
      this.options.filterBy[this.searchFields.column.value] = this.searchFields.searchValue.value;
      this.updateOptions(this.options);
    }
  }
};
</script>
